<template>
  <div class="app-container">
    <div class="filter-container" style="display: flex;flex-wrap: wrap;">
      <el-input v-model="listQuery.order_no" placeholder="请输入订单号" class="filter-item" style="width: 250px;" clearable />

      <el-select v-model="listQuery.ledger_role_id" placeholder="分账角色" filterable class="filter-item" style="width: 140px" clearable>
        <el-option v-for="item in schoolLedgerRoles" :key="item.id" :label="item.role_name" :value="item.id">
        </el-option>
      </el-select>

      <el-date-picker v-model="dateArr" type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="filter-item" style="display: flex;" clearable></el-date-picker>

      <el-button type="primary" class="filter-item" @click="handleFilter">搜索</el-button>

      <download-excel :fields="json_fields" :fetch="handleExport" type="xls" name="扫码支付分账明细.xls" worksheet="扫码支付分账明细">
        <el-button type="success" :loading="exportLoading">导出</el-button>
      </download-excel>
    </div>

    <div style="margin-bottom:10px;">
      <span style="font-size:18px;">分账总额：{{ledgerMoney}}</span>
      <span style="font-size:18px;margin-left:30px;">订单总数：{{orderCount}}</span>
    </div>

    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column label="ID" width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>

      <el-table-column label="订单号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.order_no }}
        </template>
      </el-table-column>

      <el-table-column label="分账单号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.split_order_no }}
        </template>
      </el-table-column>

      <el-table-column label="角色名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.school_ledger_role.role_name }}
        </template>
      </el-table-column>

      <el-table-column label="金额" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.money }}
        </template>
      </el-table-column>

      <el-table-column label="分账时间" min-width="160" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>

      <el-table-column label="分账结果" min-width="110" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.result === 'PENDING'">待分账</span>
          <span v-else-if="scope.row.result === 'SUCCESS'">分账成功</span>
          <span v-else-if="scope.row.result === 'CLOSED'">分账失败已关闭</span>
          <span v-else>{{ scope.row.result }}</span>
        </template>
      </el-table-column>

      <el-table-column label="分账失败原因" min-width="150" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.fail_reason === 'ACCOUNT_ABNORMAL'">分账接收账户异常</span>
          <span v-else-if="scope.row.fail_reason === 'NO_RELATION'">分账关系已解除</span>
          <span v-else-if="scope.row.fail_reason === 'RECEIVER_HIGH_RISK'">高风险接收方</span>
          <span v-else-if="scope.row.fail_reason === 'RECEIVER_REAL_NAME_NOT_VERIFIED'">接收方未实名</span>
          <span v-else-if="scope.row.fail_reason === 'NO_AUTH'">分账权限已解除</span>
          <span v-else-if="scope.row.fail_reason === 'RECEIVER_RECEIPT_LIMIT'">接收方已达收款限额</span>
          <span v-else-if="scope.row.fail_reason === 'PAYER_ACCOUNT_ABNORMAL'">分出方账户异常</span>
          <span v-else>{{ scope.row.fail_reason }}</span>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5, 10, 20, 30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";
import downloadExcel from "vue-json-excel";

export default {
  components: {
    downloadExcel
  },
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        school_id: "",
        order_no: "",
        ledger_role_id:"",
        start_date: "",
        end_date: "",
      },
      schoolLedgerRoles: [],
      dateArr: null,
      ledgerMoney: 0,
      orderCount: 0,
      json_fields: {
        订单号: {
          field: "order_no",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        分账单号: {
          field: "split_order_no",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        角色名称: "school_ledger_role.role_name",
        金额: "money",
        分账时间: {
          field: "created_at",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        }
      },
      exportLoading: false,
    };
  },
  created() {
    if (this.$route.query.ledger_role_id) {
      this.listQuery.ledger_role_id = this.$route.query.ledger_role_id-0;
    }
    if (this.$route.query.order_no) {
      this.listQuery.order_no = this.$route.query.order_no;
    }
    this.getList();
    this.getSchoolLedgerRoleList();
  },
  computed: {
    ...mapGetters(["schools", "school_id", "user"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
      this.getSchoolLedgerRoleList();
    },
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.start_date = newVal[0];
        this.listQuery.end_date = newVal[1];
      } else {
        this.listQuery.start_date = "";
        this.listQuery.end_date = "";
      }
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/scanCodeOrder/splitLog",
        method: "get",
        params: this.listQuery,
        timeout: 6000000,
      }).then((response) => {
        this.list = response.data.list.data;
        this.total = response.data.list.total;
        this.ledgerMoney = response.data.ledgerMoney;
        this.orderCount = response.data.orderCount;
        this.listLoading = false;
      });
    },
    getSchoolLedgerRoleList() {
      request({
        url: "/api/backend/school/ledgerRoleList",
        method: "get",
        params: {
          page: 1,
          limit: 100000,
          school_id: this.school_id
        }
      }).then(response => {
        this.schoolLedgerRoles = response.data.data;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleExport() {
      if (!this.listQuery.start_date || !this.listQuery.end_date) {
        this.$message({
          type: "warning",
          message: "请选择时间段"
        });
        return;
      }

      let listQuery = JSON.parse(JSON.stringify(this.listQuery));
      listQuery.page = 1;
      listQuery.limit = this.total;
      this.exportLoading = true;
      return request({
        url: "/api/backend/scanCodeOrder/splitLog",
        method: "get",
        params: listQuery,
        timeout: 6000000,
      }).then(response => {
        if (response.data.list.data.length <= 0) {
          this.$message({
            type: "warning",
            message: "暂无记录"
          });
          return;
        }
        return response.data.list.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.exportLoading = false;
      });
    }
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>
